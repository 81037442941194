import 'normalize.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import AppLoading from './components/app/AppLoading';
import './assets/i18n/i18n';

// It may seem repetitive to use process.env.REACT_APP_PRODUCT in every if statement. This repetition is however
// necessary to enable webpack tree shaking and therefore achieving a small bundle size, as webpack apparently can not
// track the value of a variable one might use instead.
const Routes = (() => {
  if (process.env.REACT_APP_PRODUCT === 'theda')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "theda" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'tlksim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "tlksim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'danfosssim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "danfosssim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'pandemic')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "pandemic" */
          './apps/pandemic/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'hpdesigner')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "hpdesigner" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'aerosim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "aerosim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'logph')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "logph" */
          './apps/logphDiagram/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'hx')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "hx" */
          './apps/hxDiagram/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'airflowsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "airflowsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'mccabethiele')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "mccabethiele" */
          './apps/mcCabeThieleDiagram/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'pluggsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "pluggsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'neasim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "neasim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'zieglersim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "zieglersim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'heatensim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "heatensim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'kautexsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "kautexsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'vaillantsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "vaillantsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'weisstechniksim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "weisstechniksim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'bdrsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "bdrsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'etsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "etsim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'virtualhousesim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "virtualhousesim" */
          './apps/tlksim/routes'
        )
    );
  if (process.env.REACT_APP_PRODUCT === 'sbcsim')
    return React.lazy(
      () =>
        import(
          /* webpackChunkName: "sbcsim" */
          './apps/tlksim/routes'
        )
    );

  throw new Error(
    'There is no product defined. Please provide the product via the REACT_APP_PRODUCT environment variable'
  );
})();

ReactDOM.render(
  <App>
    {Routes ? (
      <Suspense fallback={<AppLoading />}>
        <Routes />
      </Suspense>
    ) : (
      <div>Failed to load app.</div>
    )}
  </App>,
  document.getElementById('root')
);
