// @ts-nocheck
import theme from '../../../theme';
import chartColors from './chartColors';
import settings from '../../../settings';

const chartOptionsTlk = {
  chart: {
    zoomType: 'xy',
    displayErrors: false,
    backgroundColor: theme.palette.common.white,
    style: {
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamilyPlots,
      fontSize: theme.typography.fontSize,
    },
  },
  lang: {
    numericSymbols: [null, ' e6', ' e9', ' e12'],
  },
  boost: {
    enabled: true,
    useGPUTranslations: true,
    usePreAllocated: true,
    useAlpha: true,
    debug: {
      timeSetup: false,
      timeSeriesProcessing: false,
      timeKDTree: false,
      showSkipSummary: false,
    },
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'downloadPDF'],
        align: 'right',
        x: -25,
        y: 0,
        verticalAlign: 'bottom',
      },
    },
    chartOptions: {
      title: {
        style: {
          fontSize: theme.typography.fontSize,
        },
      },
      subtitle: {
        style: {
          fontSize: theme.typography.fontSize,
        },
      },
      xAxis: {
        title: {
          style: {
            fontSize: theme.typography.fontSize,
          },
        },
        labels: {
          style: {
            fontSize: theme.typography.fontSize,
          },
        },
      },
      yAxis: {
        title: {
          style: {
            fontSize: theme.typography.fontSize,
          },
        },
        labels: {
          style: {
            fontSize: theme.typography.fontSize,
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: theme.typography.fontSize,
        },
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          style: {
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
      },
    },
  },
  xAxis: {
    title: {
      style: {
        fontSize: theme.typography.fontSize,
      },
    },
    labels: {
      style: {
        fontSize: theme.typography.fontSize,
      },
    },
    dateTimeLabelFormats: {
      month: '%b',
    },
  },
  yAxis: {
    title: {
      style: {
        fontSize: theme.typography.fontSize,
      },
    },
    labels: {
      style: {
        fontSize: theme.typography.fontSize,
      },
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    x: 0,
    y: 0,
    borderWidth: 0,
    itemStyle: {
      fontSize: theme.typography.fontSize,
    },
  },
  colors: [
    chartColors.BLUE,
    chartColors.RED,
    chartColors.GREEN,
    chartColors.LILAC,
    chartColors.ORANGE,
    chartColors.DARKGREY,
    chartColors.AQUA,
    chartColors.YELLOW,
    chartColors.PURPLE,
  ],
  plotOptions: {
    series: {
      cursor: 'pointer',
      marker: {
        enabled: false,
      },
      // threshold are set high, to disable boost for most charts, because boost enables some css
      turboThreshold: 1e9,
      // number points, where highcharts stops checking data points format
      boostThreshold: 1e9, // number points, where series enters boost mode
    },
    line: {
      lineWidth: 1,
    },
    pie: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: theme.typography.fontSize,
        },
      },
    },
    solidgauge: {
      dataLabels: {
        y: -40,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
};
const chartOptionsAerosim = { ...chartOptionsTlk, colors: [chartColors.BLUE, chartColors.LIGHTBLUE, chartColors.GREY] };

const getChartOptions = () => {
  if (settings.product === 'aerosim') {
    return chartOptionsAerosim;
  }

  return chartOptionsTlk;
};

const chartOptions = getChartOptions();
export default chartOptions;
