import settings from '../../../settings';

const chartColorsTLK = {
  BLUE: '#009abc',
  BLUE_RGBA_03: 'rgba(0, 154, 188, 0.3)',
  BLUE2: '#34adc8',
  BLUE3: '#67c2d7',
  BLUE4: '#99d7e4',
  RED: '#981e32',
  RED_RGBA_03: 'rgba(152, 30, 50, 0.3)',
  RED2: '#af3b4e',
  RED3: '#ad4b5a',
  RED4: '#c17784',
  GREEN: '#4caa6f',
  GREEN_RGBA_03: 'rgba(76, 170, 111, 0.3)',
  GREEN2: '#6fbb8c',
  GREEN3: '#94cca9',
  GREEN4: '#b7ddc4',
  PURPLE: '#6051b3',
  PURPLE_RGBA_03: 'rgba(96, 81, 179, 0.3)',
  PURPLE2: '#8073c2',
  PURPLE3: '#a097d0',
  PURPLE4: '#bfb8e1',
  ORANGE: '#ff962c',
  ORANGE_RGBA_03: 'rgba(255, 150, 44, 0.3)',
  ORANGE2: '#ffaa53',
  ORANGE3: '#ffc181',
  ORANGE4: '#ffd5ab',
  DARKGREY: '#20282a',
  DARKGREY_RGBA_03: 'rgba(32, 43, 49, 0.3)',
  DARKGREY2: '#4d545a',
  DARKGREY3: '#798184',
  DARKGREY4: '#a6aaad',
  YELLOW: '#c9c40f',
  YELLOW_RGBA_03: 'rgba(201, 196, 15, 0.3)',
  YELLOW2: '#d5d040',
  YELLOW3: '#dedc6d',
  YELLOW4: '#e9e69f',
  AQUA: '#1ab9ac',
  AQUA_RGBA_03: 'rgba(26, 185, 172, 0.3)',
  AQUA2: '#48c7bc',
  AQUA3: '#76d5cd',
  AQUA4: '#a2e3dd',
  GREY: '#2B363A',
  GREY_RGBA_03: 'rgba(43, 54, 58, 0.3)',
  GREY2: '#555f61',
  GREY3: '#82858a',
  GREY4: '#aab0b0',
  BROWN: '#6f574f',
  BROWN_RGBA_03: 'rgba(111, 87, 79, 0.3)',
  BROWN2: '#8c7972',
  BROWN3: '#a99a95',
  BROWN4: '#c5bbb9',
  LILAC: '#891a6f',
  LILAC_RGBA_03: 'rgba(137, 26, 111, 0.3)',
  LILAC2: '#a1488c',
  LILAC3: '#b777a9',
  LILAC4: '#d0a3c4',
  WHITE: '#fff',
  BLACK: '#000',
};
export const chartColorsAerosim = {
  BLUE: '#34adc8',
  LIGHTBLUE: '#99d7e4',
  GREY: '#4d545a',
  GREEN: 'rgba(0,255,0,0.5)',
  YELLOW: 'rgba(255,165,0,0.5)',
  RED: 'rgba(255,2,2,0.5)',
};

const getChartColors = () => {
  if (settings.product === 'aerosim') {
    return chartColorsAerosim;
  }

  return chartColorsTLK;
};

const chartColors = getChartColors();
export default chartColors;
