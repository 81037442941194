const chartDashStyles = [
  'Solid',
  'LongDash',
  'Dot',
  'LongDashDot',
  'LongDashDotDot',
  'ShortDash',
  'DashDot',
  'ShortDashDot',
  'ShortDashDotDot',
  'ShortDot',
  'LongDash',
];
export default chartDashStyles;
