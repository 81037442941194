import React, { useState, useContext } from 'react';

type contextType = {
  openedModal: string | null;
  setOpenedModal: (modal: string | null) => void;
  closeModal: () => void;
};

export const ModalContext: React.Context<contextType> = React.createContext<contextType>({
  openedModal: null,
  setOpenedModal: () => {},
  closeModal: () => {},
});

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [openedModal, setOpenedModal] = useState<string | null>(null);

  const closeModal = () => setOpenedModal(null);

  window.onpopstate = closeModal;
  return (
    <ModalContext.Provider
      value={{
        openedModal,
        setOpenedModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): contextType => useContext(ModalContext);

export default ModalProvider;
