import React, { useState, useEffect, useContext } from 'react';
import httpService from '../../services/http';
import { isEmpty } from '../../utils/helper/helper';
import { useStructureContext } from '../StructureProvider';

type Props = {
  children: React.ReactNode;
};

type RefrigerantList = Array<{
  id: number;
  name: string;
  refrigerant: {
    id: number;
    name: string;
    tilmedia_name: string;
    vapor_pressure_curve: Object;
  };
}>;

type GasList = Array<{
  id: number;
  name: string;
  gas: {
    id: number;
    name: string;
    tilmedia_name: string;
  };
  satured_data: {
    p: number;
    T_v: number;
    T_l: number;
  };
}>;

type contextType = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refrigerantList: RefrigerantList;
  refrigerantSelectList: Array<{
    value: number;
    label: string;
  }>;
  gasList: GasList;
  gasSelectList: Array<{
    value: number;
    label: string;
  }>;
  getMediaDataRefrigerant: any;
  getMediaDataMoistAir: any;
  parameter: Array<{ [key: string]: number }>;
  setInitialParameter: any;
};

export const UIParameterContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const UIParameterProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refrigerantList, setRefrigerantList] = useState<RefrigerantList>([]);
  const [refrigerantSelectList, setRefrigerantSelectList] = useState<
    Array<{
      value: number;
      label: string;
    }>
  >([]);
  const [gasList, setGasList] = useState<GasList>([]);
  const [gasSelectList, setGasSelectList] = useState<
    Array<{
      value: number;
      label: string;
    }>
  >([]);
  const [parameter, setParameter] = useState<Array<{ [key: string]: number }>>([]);
  const { initialValues, isLoadingStructure } = useStructureContext();

  /**
   * Function can makes sure that old simulations work with up-to-date parameter structure
   * If no parameters are passed, default initial parameter are set.
   * @param parameter list of parameters
   * @returns {*}
   */
  const setInitialParameter = (parameters: any = {}) => {
    const withInitial: { [key: string]: number } = { ...initialValues, ...parameters };
    setParameter([withInitial]);
    if (!isEmpty(withInitial)) setIsLoading(false);
  };

  /**
   * @description is called to get list of available refrigerants
   */
  const getRefrigerantList = async () => {
    const response = await httpService.get('fluiddata/logphstatechart/', {}, 'json', true);
    const listRefrigerants: RefrigerantList = response?.data || [];
    const selectList = listRefrigerants.map((l) => ({
      value: l.id,
      label: l.name,
    }));
    setRefrigerantList(listRefrigerants);
    setRefrigerantSelectList(selectList);
  };

  /**
   * @description is called to get list of available gases
   */
  const getGasList = async () => {
    const response = await httpService.get('fluiddata/hxstatechart/', {}, 'json', true);
    const listGases: GasList = response?.data || [];
    const selectList = listGases.map((l) => ({
      value: l.id,
      label: l.name,
    }));
    setGasList(listGases);
    setGasSelectList(selectList);
  };

  /**
   * @description is called to get media data of one refrigerant,
   * @param idRefrigerant id of refrigerant in database
   */
  const getMediaDataRefrigerant = async (idRefrigerant?: number) => {
    if (!idRefrigerant) return {};
    const response = await httpService.get(`fluiddata/logphstatechart/${idRefrigerant}/`, {}, 'json', true);
    return response?.data || {};
  };

  /**
   * @description is called to get h1px diagram media data
   * for moist air at a given pressure (referenced by idGas)
   * @param id of air pressure
   */
  const getMediaDataMoistAir = async (idGas?: number) => {
    const response = await httpService.get(`fluiddata/hxstatechart/${idGas}/`, {}, 'json', true);
    const hxStateChartDataPlot = {
      name: response?.data?.name,
      plot_settings: response?.data?.plot_settings || [],
      h1pxIsolines: response?.data?.h_isolines || [],
      TIsolines: response?.data?.t_isolines || [],
      phiIsolines: response?.data?.phi_isolines || [],
      areaUnsaturatedAir: response?.data?.area_unsaturated_air || [],
    };
    return hxStateChartDataPlot;
  };

  useEffect(() => {
    getRefrigerantList();
    getGasList();
  }, []);
  useEffect(() => {
    setInitialParameter();
  }, [isLoadingStructure]);
  return (
    <UIParameterContext.Provider
      value={{
        refrigerantList,
        refrigerantSelectList,
        gasList,
        gasSelectList,
        getMediaDataRefrigerant,
        getMediaDataMoistAir,
        isLoading,
        setIsLoading,
        parameter,
        setInitialParameter,
      }}
    >
      {children}
    </UIParameterContext.Provider>
  );
};

export const useUIParameterContext = (): contextType => {
  const context = useContext(UIParameterContext);

  if (context === undefined) {
    throw new Error('useUIParameterContext must be used within a UIParameterProvider');
  }

  return context;
};
export default UIParameterProvider;
