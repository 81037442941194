// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import httpService, { storedAccessToken } from '../../services/http';
import { isEmpty } from '../../utils/helper/helper';
import { useUserDataContext } from '../UserDataProvider';
import * as unitsHelper from '../../utils/helper/units';
import settings from '../../settings';
import type { Form, Quantity, UiSetupConfig, UiResultsConfig, Unit, Variable } from './types';

type Props = {
  children: React.ReactNode;
};

export type contextType = {
  getFmuList: (...args: Array<any>) => any;
  selectedFmu: any;
  setSelectedFmu: any;
  getFmuData: (...args: Array<any>) => any;
  fmuSelectList: Array<{
    value: number;
    label: string;
  }>;
  isLoadingStructure: boolean;
  uiSetupConfig: UiSetupConfig;
  interactiveSimulationMode: boolean;
  setInteractiveSimulationMode: any;
  inputsAvailable: boolean;
  menuLevel2: Array<Array<string>>;
  parameterMenuIcons: Array<string>;
  initialValues: any;
  variables: Array<any>;
  uiResultsConfig: UiResultsConfig;
  outputInterval: number;
  setOutputInterval: any;
  forms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  interactiveForms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  quantities: Array<Quantity>;
  units: Array<Unit>;
  parameterList: Array<Variable>;
  uiVariablesList: Array<Variable>;
  hiddenParameterList: Array<any>;
  fmuComponents: Array<any>;
  inputsList: Array<Variable>;
};

export const StructureContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const StructureProvider = ({ children }: Props) => {
  // FMU selection states
  const [selectedFmu, setSelectedFmu] = useState(undefined);
  const [fmuSelectList, setFmuSelectList] = useState([]);
  // FMU data states
  const [uiSetupConfig, setUiSetupConfig] = useState([]);
  const [uiInputConfig, setUiInputConfig] = useState([]);
  const [variables, setVariables] = useState([]);
  const [uiResultsConfig, setUiResultsConfig] = useState([]);
  const [teams, setTeams] = useState([]);
  const [forms, setForms] = useState([]);
  const [interactiveForms, setInteractiveForms] = useState([]);
  const [interactiveSimulationMode, setInteractiveSimulationMode] = useState(false);
  const [inputsAvailable, setInputsAvailable] = useState(false);
  const [outputInterval, setOutputInterval] = useState(1);

  const [parameterList, setParameterList] = useState([]); // includes hidden parameter, too
  const [uiVariablesList, setUiVariablesList] = useState([]); // without hidden parameter
  const [inputsList, setInputsList] = useState([]); // includes inputs parameter, too

  const [hiddenParameterList, setHiddenParameterList] = useState([]); // hidden parameter only

  // structure
  const [menuLevel2, setMenuLevel2] = useState([[]]);
  const [parameterMenuIcons, setParameterMenuIcons] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [initialInputValues, setInitialInputValues] = useState([{}]);

  // units
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [quantities, setQuantities] = useState<Array<Quantity>>([]);
  // const [isLoadingFmus, setIsLoadingFmus] = useState(true);
  const [isLoadingStructure, setIsLoadingStructure] = useState(true);
  const [fmuComponents, setFmuComponents] = useState([]);

  const userDataContext = useUserDataContext();

  // TODO: Move two functions below to parameterContext?

  /**
   * @description This function builds a list of all variables objects
   * in the uiSetupConfig
   * @param uiSetupConfigComplete two level json data structure
   * @returns {*}
   */
  const getVariableList = (uiSetupConfigComplete: UiSetupConfig): any =>
    uiSetupConfigComplete.flatMap((menu) => menu.subMenu).flatMap((subMenu) => subMenu.variables);

  /**
   * @description This function gets the initial variables from the uiSetupConfigComplete
   * for each given mainMenu item.
   * @param uiSetupConfigComplete two level json data structure
   * @param mainMenuItems array of mainMenu names
   * @returns {*}
   */
  const getInitialValues = (uiSetupConfigComplete: UiSetupConfig, mainMenuItems: Array<string>) => {
    const initValues = {};
    // filter uiStructureArr with mainMenuItems
    const uiStructureFiltered = uiSetupConfigComplete.filter((j) => mainMenuItems.includes(j.name));
    // get a flat variable list
    const variableList = getVariableList(uiStructureFiltered);
    // go through variables list and write initial values for Formik
    variableList.forEach((v) => {
      if (v.inputType === 'variableInput') {
        if (v.variableInput) {
          if (v.variableInput.start) {
            const startValues = [];
            v.variableInput.start.forEach((el, indStartValues) => {
              const rowValues = {
                id: indStartValues,
              };
              v.variableInput.columnNames.forEach((name, indColumnName) => {
                rowValues[name] = el[indColumnName];
              });
              startValues.push(rowValues);
            });
            initValues[v.name] = startValues;
          }
        }
      } else if (['Table', 'InputTable'].includes(v.inputType)) {
        initValues[v.name] = v.arrays.start;
        initValues[`${v.name}_dataSource`] = v.dataSource;
      } else if (v.inputType !== 'Header') {
        // make sure that 'Header' are not added to initialValues
        initValues[v.name] = v.start;
      }
    });
    return initValues;
  };

  /**
   * @description is called to parse list of forms from structure json
   * @param uiStructureArr json data structure of main and sub menus
   */
  const getForms = (uiSetupConfigComplete: UiSetupConfig, interactiveSim = false) => {
    const formObjects = [];
    uiSetupConfigComplete.forEach((uiS) => {
      const lvl2Forms = [];

      if (uiS.name !== 'Hidden') {
        uiS.subMenu.forEach((uiSel) => {
          // standard form object if structure json entry empty
          const standardFormObj = interactiveSim
            ? {
                name: 'InteractiveForm',
              }
            : {
                name: 'StandardForm',
              };

          if (!uiSel.form) {
            lvl2Forms.push({
              name: uiSel.name,
              form: standardFormObj,
            });
          } else {
            lvl2Forms.push({
              name: uiSel.name,
              form: uiSel.form,
            });
          }
        });
        formObjects.push({
          name: uiS.name,
          forms: lvl2Forms,
        });
      }
    });
    return formObjects;
  };

  const getFmuComponents = (fmuId: number) => {
    httpService
      .get(`fmus/${fmuId}/components/`, {}, 'json', true, 'v2')
      .then((response) => {
        setFmuComponents(response.data);
        return true;
      })
      .catch((error) => error);
  };

  /**
   * @description is called on each selection of an FMU
   */
  const selectFmu = (fmu: any) => {
    try {
      localStorage.setItem('persistentFmuSelection', JSON.stringify(selectedFmu.id));

      let uiSetupConfigComplete = fmu.ui_setup_config;

      const { inputs } = fmu;
      if (inputs) uiSetupConfigComplete = inputs.concat(uiSetupConfigComplete);

      if (inputs) {
        setInputsAvailable(true);
      } else {
        setInputsAvailable(false);
      }

      setForms(getForms(uiSetupConfigComplete));
      setInteractiveForms(getForms(uiSetupConfigComplete, true)); // not ideal

      setParameterList(getVariableList(uiSetupConfigComplete));
      setUiVariablesList(getVariableList(uiSetupConfigComplete.filter((el) => el.name !== 'Hidden')));
      if (inputs) setInputsList(getVariableList(inputs));

      const hiddenValueStructure = uiSetupConfigComplete.filter((el) => el.name === 'Hidden');

      if (hiddenValueStructure.length > 0) {
        setHiddenParameterList(hiddenValueStructure[0].subMenu[0].variables);
      } else {
        setHiddenParameterList([]);
      }
      setVariables([
        ...fmu.model_description.parameters,
        ...fmu.model_description.inputs,
        ...fmu.model_description.outputs,
      ]);
      setUiResultsConfig(fmu.ui_results_config);
      setUiInputConfig(inputs);
      setTeams(fmu.teams);
      setInteractiveSimulationMode(false);
      getFmuComponents(fmu.id);

      const newUiSetupConfig = uiSetupConfigComplete.filter((el) => el.name !== 'Hidden');

      if (!isEmpty(newUiSetupConfig)) {
        setParameterMenuIcons(newUiSetupConfig.map((el) => el.icon || ''));
        setMenuLevel2(newUiSetupConfig.map((el) => el.subMenu.map((el2) => el2.name)));
        setInitialValues(
          getInitialValues(
            newUiSetupConfig,
            newUiSetupConfig.map((el) => el.name)
          )
        );
        setIsLoadingStructure(false);
      }

      setUiSetupConfig(newUiSetupConfig);

      return true;
    } catch (error) {
      return error;
    }
  };

  /**
   * @description is called to get list of available FMUs
   */
  const getFmuList = () => {
    httpService
      .get(`fmu/${settings.product}/`, {}, 'json', true)
      .then((response) => {
        const listFMU = response.data;
        // sort public FMUs to bottom of list
        const sortedListFMU = [...listFMU.filter((i) => !i.is_public), ...listFMU.filter((i) => i.is_public)];
        // build an array for select component with id and name, for public FMUs add '(public)'
        const selectList = sortedListFMU.map((l) => {
          if (l.is_public)
            return {
              value: l.id,
              label: `${l.display_name} (public)`,
            };
          return {
            value: l.id,
            label: l.display_name,
          };
        });
        setFmuSelectList(selectList);
        return true;
      })
      .catch((error) => error);
  };

  /**
   * @description is called to get data of one FMU,
   * if no id is given, the first FMU in the list is returned
   * @param id of fmu
   */
  const getFmuData = (id?: number) => {
    if (!isEmpty(fmuSelectList)) {
      const idFmu = id || fmuSelectList[0].value;
      httpService
        .get(`fmu/${settings.product}/${idFmu}/`, {}, 'json', true)
        .then((response) => {
          setSelectedFmu(response.data);
          return true;
        })
        .catch((error) => error);
    }
  };

  /**
   * @description handles the initial set of quantities and units
   */
  useQuery(
    ['quantities'],
    async () => {
      const response = await httpService.get('units/quantity/');
      return response.data;
    },
    {
      onSuccess: (data) => {
        setQuantities(data);
      },
    }
  );
  useQuery(
    ['units'],
    async () => {
      const response = await httpService.get('units/unit/');
      return response.data;
    },
    {
      onSuccess: (data) => {
        setUnits(data);
      },
    }
  );

  /**
   * @description handles initial input values for changes
   * in inputsAvailable or interactiveSimulationMode
   */
  useEffect(() => {
    if (!isEmpty(uiSetupConfig) && interactiveSimulationMode && inputsAvailable) {
      setInitialInputValues([getInitialValues(uiSetupConfig, ['Inputs'])]);
    }
  }, [interactiveSimulationMode, inputsAvailable, uiSetupConfig]);

  /**
   * @description is executed on every change of selectedFmu and
   * if selectedFmu is valid, it calls selectFm()
   */
  useEffect(() => {
    if (selectedFmu && selectedFmu.id && fmuSelectList.find((fmu) => fmu.value === selectedFmu.id)) {
      selectFmu(selectedFmu);
    }
  }, [selectedFmu]);

  /**
   * @description is executed on every change of fmuSelectList
   * and gets the FMU data for first FMU in list
   */
  useEffect(() => {
    if (storedAccessToken()) {
      const cachedFmu = JSON.parse(localStorage.getItem('cachedFmu') || 'null');
      const persistentFmuSelection = JSON.parse(localStorage.getItem('persistentFmuSelection') || 'null');

      if (!selectedFmu || !selectedFmu.id || !fmuSelectList.find((fmu) => fmu.value === selectedFmu.id)) {
        if (fmuSelectList.find((i) => i.value === cachedFmu)) getFmuData(cachedFmu);
        else if (fmuSelectList.find((i) => i.value === persistentFmuSelection)) getFmuData(persistentFmuSelection);
        else getFmuData();
      } else {
        selectFmu(selectedFmu);
      }
    }
  }, [fmuSelectList]);

  /**
   * @description is executed on every change of user
   * and gets the fmu list
   */
  useEffect(() => {
    if (storedAccessToken()) {
      getFmuList();
    }
  }, [userDataContext.email]);

  return (
    <StructureContext.Provider
      value={{
        getFmuList,
        getFmuData,
        selectedFmu,
        setSelectedFmu,
        fmuSelectList,
        isLoadingStructure,
        uiSetupConfig,
        uiInputConfig,
        interactiveSimulationMode,
        setInteractiveSimulationMode,
        inputsAvailable,
        menuLevel2,
        parameterMenuIcons,
        initialValues,
        initialInputValues,
        variables,
        uiResultsConfig,
        teams,
        outputInterval,
        setOutputInterval,
        forms,
        interactiveForms,
        units,
        quantities,
        parameterList,
        uiVariablesList,
        inputsList,
        hiddenParameterList,
        fmuComponents,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};

export const useStructureContext = (): contextType => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useStructureContext must be used within a StructureProvider');
  }

  return context;
};
export const useUnitHelper = (): {
  getQuantityOfUnit: (unitName: string) => Quantity | void;
  getRelatedUnits: (quantity: Quantity) => Array<Unit>;
  getAllUnits: () => Array<Unit>;
  unitConversion: (currentUnitName: string, currentValue: number, newUnitName: string) => number;
  unitsCompatible: (unit1: string, unit2: string) => boolean;
} => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useUnitConversion must be used within a StructureProvider');
  }

  const { getQuantityOfUnit, getRelatedUnits, getAllUnits, unitConversion, unitsCompatible } = unitsHelper;

  /**
   * @description returns value transformed to new unit,
   * if current and new unit represent the same quantity,
   * else the current value is returned.
   * @param currentUnitName
   * @param currentValue
   * @param newUnitName
   * @return {}
   */
  const prefilledUnitConversion = (currentUnitName: string, currentValue: number, newUnitName: string): number =>
    unitConversion(context.units, context.quantities, currentUnitName, currentValue, newUnitName);

  /**
   * @description checks if both units exist and belong to the same quantity
   * @param unit1
   * @param unit2
   * @return {}
   */
  const prefilledUnitsCompatible = (unit1: string, unit2: string): boolean =>
    unitsCompatible(context.units, context.quantities, unit1, unit2);

  const prefilledGetQuantityOfUnit = (unitName: string): Quantity | void =>
    getQuantityOfUnit(context.units, context.quantities, unitName);

  const prefilledGetRelatedUnits = (quantity: Quantity): Array<Unit> => getRelatedUnits(context.units, quantity);

  const prefilledGetAllUnits = (): Array<Unit> => getAllUnits(context.units);

  return {
    getQuantityOfUnit: prefilledGetQuantityOfUnit,
    getRelatedUnits: prefilledGetRelatedUnits,
    getAllUnits: prefilledGetAllUnits,
    unitConversion: prefilledUnitConversion,
    unitsCompatible: prefilledUnitsCompatible,
  };
};
export default StructureProvider;
