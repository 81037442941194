// @ts-nocheck
import React from 'react';
import styled from 'styled-components/macro';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import theme from '../../../theme';
import i18n from '../../../assets/i18n/i18n';
import { checkIsObject } from '../../../utils/helper/helper';

type Props = {
  TranslatedHeader: any;
  Content: any;
  toastId?: string;
  position?: string;
  autoClose?: number;
  hideProgressBar?: boolean;
};
type ErrorMsgProps = {
  content: any;
};
const DarkFlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin: 1.5em;

  color: ${(props) => props.theme.palette?.tertiary.contrastText};
  text-align: center;
`;
const LightFlexContainer = styled(DarkFlexContainer)`
  color: ${(props) => props.theme.palette?.tertiary.contrastText};
`;
const ErrorFlexContainer = styled(DarkFlexContainer)`
  color: ${(props) => props.theme.palette?.warning.contrastText};
`;
const Header = styled.h3`
  margin: 0;
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: bold;
`;

/**
 * @description ToastContainer should only be rendered once in the application tree
 * and is used to render emitted toasters
 */
export const StyledToastContainer: any = styled(ToastContainer)`
  .Toastify__toast {
    margin-bottom: 0;
    background: ${(props) => props.theme.palette?.secondary.main};
  }

  width: auto !important;

  /* Mobile Styles */
  @media (max-width: 600px) {
    max-width: 60vw;
    /*
     * Sets default center position of ToastContainer as mobile styling is currently 
     * broken in react-toastify. This should hopefully be fixed soon in an update.
     **/
    left: 20% !important;
  }

  /* Tablet Styles */
  @media (min-width: 600px) {
    max-width: 35vw;
  }

  /* Desktop Styles */
  @media (min-width: 1200px) {
    max-width: 30vw;
  }
`;
export const emitDarkToaster = ({ TranslatedHeader, Content, ...rest }: Props) => {
  toast.dark(
    <DarkFlexContainer>
      <Header>
        <TranslatedHeader />
      </Header>
      <Content />
    </DarkFlexContainer>,
    { ...rest }
  );
};
export const emitLightToaster = ({
  TranslatedHeader,
  Content,
  autoClose,
  position,
  hideProgressBar,
  toastId,
  ...rest
}: Props) => {
  toast(
    <LightFlexContainer>
      <Header>
        <TranslatedHeader />
      </Header>
      <Content />
    </LightFlexContainer>,
    {
      position,
      autoClose,
      hideProgressBar,
      toastId,
      ...rest,
      style: {
        background: theme.palette.tertiary.main,
      },
      bodyStyle: {
        background: theme.palette.tertiary.main,
      },
    }
  );
};
export const emitSuccessToaster = ({ TranslatedHeader, Content, ...rest }: Props) => {
  toast(
    <LightFlexContainer>
      <Header>
        <TranslatedHeader />
      </Header>
      <Content />
    </LightFlexContainer>,
    {
      ...rest,
      style: {
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      },
      bodyStyle: {
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      },
    }
  );
};

const checkIsStringOrNumber = (item) => {
  return (
    typeof item === 'string' || (typeof item === 'number' && item !== null && item !== 'object' && !Array.isArray(item))
  );
};

export const emitErrorMsgToaster = ({ content, ...rest }: ErrorMsgProps) => {
  toast.warn(
    <ErrorFlexContainer>
      <Header>{i18n.t('Error')}</Header>
      {checkIsObject(content) &&
        Object.values(content).map((el) => <div key={`${Date.now()}`}>{checkIsObject(el) || el}</div>)}
      {Array.isArray(content) && content.map((el) => <div key={`${Date.now()}`}>{checkIsObject(el) || el}</div>)}
      {checkIsStringOrNumber(content) && <div key={`${Date.now()}`}>{content}</div>}
    </ErrorFlexContainer>,
    {
      ...rest,
      style: {
        color: theme.palette.error.contrastText,
        background: theme.palette.error.main,
      },
      bodyStyle: {
        color: theme.palette.error.contrastText,
        background: theme.palette.error.main,
      },
    }
  );
};
export const emitMobileNotificationToaster = ({ content, ...rest }: ErrorMsgProps) => {
  toast.warn(
    <ErrorFlexContainer>
      {checkIsObject(content) &&
        Object.values(content).map((el) => <div key={`${Date.now()}`}>{checkIsObject(el) || el}</div>)}
      {Array.isArray(content) && content.map((el) => <div key={`${Date.now()}`}>{checkIsObject(el) || el}</div>)}
      {checkIsStringOrNumber(content) && <div key={`${Date.now()}`}>{content}</div>}
    </ErrorFlexContainer>,
    {
      ...rest,
      style: {
        background: theme.palette.warning.main,
        fontSize: theme.fontSize,
      },
      bodyStyle: {
        background: theme.palette.warning.main,
      },
    }
  );
};
export default StyledToastContainer;
