import React from 'react';
import styled from 'styled-components/macro';

const LoadingMessage = styled.div`
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation-delay: 0.75s;
  animation-duration: 0.75s;
  animation-fill-mode: both;
  animation-name: fadein;
`; // TODO: Replace with a nice spinner

export default function AppLoading() {
  return <LoadingMessage>Loading...</LoadingMessage>;
}
