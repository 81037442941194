/**
 * This file defines app-wide constants. Please make everything that makes sense
 * (e.g. might differ between local, staging, prod) available as environment variable.
 * The default value should be the one used for local development.
 */
// NOTE: The name always has to start with REACT_APP_ or is ignored by react
const settings = {
  apiUrl: process.env.REACT_APP_API_URL,
  wsUrl: process.env.REACT_APP_WS_URL,
  product: process.env.REACT_APP_PRODUCT,
  jsonSchemaVersion: process.env.REACT_APP_JSON_SCHEMA_VERSION,
};

export default settings;
