import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import commonDE from './common-de.json';
import commonEN from './common-en.json';
import thedaDE from './theda-de.json';
import thedaEN from './theda-en.json';
import tlksimDE from './tlksim-de.json';
import tlksimEN from './tlksim-en.json';
import pandemicDE from './pandemic-de.json';
import pandemicEN from './pandemic-en.json';
import logphDE from './logph-de.json';
import logphEN from './logph-en.json';
import hxDE from './hx-de.json';
import hxEN from './hx-en.json';
import mcCabeThieleDE from './mcCabeThiele-de.json';
import mcCabeThieleEN from './mcCabeThiele-en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    // we init with resources
    resources: {
      de: {
        common: commonDE,
        theda: thedaDE,
        tlksim: tlksimDE,
        pandemic: pandemicDE,
        logph: logphDE,
        hx: hxDE,
        mcCabeThiele: mcCabeThieleDE,
      },
      en: {
        common: commonEN,
        theda: thedaEN,
        tlksim: tlksimEN,
        pandemic: pandemicEN,
        logph: logphEN,
        hx: hxEN,
        mcCabeThiele: mcCabeThieleEN,
      },
    },
    fallbackLng: 'de',
    fallbackNS: 'common',
    debug: false,
    // have a common namespace used around the full app
    ns: ['common', 'theda', 'tlksim', 'pandemic'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react
    },
  });
export default i18n;
