import React, { useState, useEffect, useContext } from 'react';
import httpService, { storedAccessToken } from '../../services/http';

type Props = {
  children: React.ReactNode;
};
type contextType = {
  email: string;
  firstName: string;
  lastName: string;
  teamId: number;
  teamName: string;
  isStaff: boolean;
  setUserData: any;
  isDemo: boolean;
  setIsDemo: any;
  simName: string;
  setSimName: any;
  permissions: string[];
  user:
    | {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
        hubspotintegration: number;
        company: string;
        team: {
          id: number;
          name: string;
        };
      }
    | any;
};

export const UserDataContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const UserDataProvider = ({ children }: Props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teamId, setTeamId] = useState(-1);
  const [teamName, setTeamName] = useState('');
  const [isDemo, setIsDemo] = useState(false);
  const [simName, setSimName] = useState('New Simulation');
  const [user, setUser] = useState({});
  const [isStaff, setIsStaff] = useState(false);
  const [permissions, setPermissions] = useState([]);

  /**
   * @description is called to set user data
   */
  const setUserData = () => {
    httpService
      .get('user/', {}, 'json', true)
      .then((response) => {
        setUser(response.data[0]);
        setEmail(response.data[0].email);
        setFirstName(response.data[0].first_name);
        setLastName(response.data[0].last_name);
        setIsStaff(response.data[0].is_staff);
        setPermissions(response.data[0].permissions);
        sessionStorage.setItem('userId', response.data[0].id);

        if (response.data[0].last_name === 'Demo' && response.data[0].first_name === 'User') {
          setIsDemo(true);
        }

        if (response.data[0].team.id) {
          setTeamId(response.data[0].team.id);
          setTeamName(response.data[0].team.name);
        }

        return true;
      })
      .catch((error) => error);
  };

  useEffect(() => {
    if (storedAccessToken()) {
      setUserData();
    }
  }, []);
  return (
    <UserDataContext.Provider
      value={{
        email,
        firstName,
        lastName,
        teamId,
        teamName,
        isStaff,
        setUserData,
        isDemo,
        setIsDemo,
        simName,
        setSimName,
        permissions,
        user,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = (): contextType => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserDataContext must be used within a UserDataProvider');
  }

  return context;
};
export default UserDataProvider;
