/* eslint-disable class-methods-use-this */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import settings from '../settings';
import { get, transformApiErrors } from '../utils/helper/helper';
import { emitErrorMsgToaster } from '../components/common/Toaster';

const REQUEST_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;
export const storedAccessToken = () => localStorage.getItem('access') || sessionStorage.getItem('access');
export const storedRefreshToken = () => localStorage.getItem('refresh') || sessionStorage.getItem('refresh');
/**
 * @class
 * @description This service class handles all REST Calls
 */

class HttpService {
  private service: AxiosInstance;

  /**
   * @function
   * @description Init the axios library and register the interceptors.
   */
  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      }, // withCredentials: true, // Re-add if you have problems with CORS
    });
    // interceptor needed for HERE Autocomplete API because it does not allow headers
    this.service.interceptors.request.use(
      (config) => {
        const token = storedAccessToken();
        const conf = config;

        if (token) {
          conf.headers = { ...config.headers, Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        }

        return conf;
      },
      (error) => Promise.reject(error)
    );
    this.service.interceptors.response.use(
      (response) => {
        return response;
      }, // intercept here if access token is expired
      (error) => {
        const originalRequest = error.config;

        if (
          error.response?.status === 401 &&
          get(() => error.response.data.messages[0].token_type) === 'access' &&
          get(() => error.response.data.messages[0].message) === 'Token is invalid or expired'
        ) {
          const refreshToken = storedRefreshToken();
          return this.post(
            'token/refresh/',
            {
              refresh: refreshToken,
            },
            undefined
          )
            .then((response) => {
              this.setAccessToken(response.data.access);
              this.service.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              return this.service(originalRequest);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                this.removeAuthToken();

                if (window.location.pathname !== '/') {
                  window.location.href = '/';
                }
              }
            });
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * @function
   * @description Set the access token in the request header. Call this after successful login.
   * @param {string} token is a token string.
   * @param {boolean} stayLoggedIn logged in.
   */
  setAccessToken = (token: string, stayLoggedIn = true) => {
    // this.service.defaults.headers.common.Authorization = token ? `Token ${token}` : '';
    if (stayLoggedIn) {
      localStorage.setItem('access', token);
    } else {
      sessionStorage.setItem('access', token);
    }
  };

  /**
   * @function
   * @description Set the refresh token in the request header. Call this after successful login.
   * @param {string} token is a token string.
   * @param {boolean} stayLoggedIn logged in.
   */
  setRefreshToken = (token: string, stayLoggedIn = true) => {
    // this.service.defaults.headers.common.Authorization = token ? `Token ${token}` : '';
    if (stayLoggedIn) {
      localStorage.setItem('refresh', token);
    } else {
      sessionStorage.setItem('refresh', token);
    }
  };

  /**
   * @function
   * @description Removes the access and refresh token in the header. Call this after logout.
   */
  removeAuthToken = () => {
    // delete this.service.defaults.headers.common.Authorization;
    localStorage.removeItem('access');
    sessionStorage.removeItem('access');
    localStorage.removeItem('refresh');
    sessionStorage.removeItem('refresh');
  };

  /**
   * @function
   * @description Handles unauthorized request, redirects to login
   */
  unauthorizedHandler = () => {
    this.removeAuthToken();

    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
  };

  /**
   * @description Method to handle the request by method.
   * @param method {string} th request method type.
   * @param {string} path A relative path that comes after the API URL defined in settings.js, e.g. "reports"
   * @param {*} data is an object with API data.
   * @param {*} params is an object with API params.
   * @param {boolean} logError controls whether errorToaster is emitted.
   * @return {Promise<AxiosResponse<any>>}
   */
  handleRequest = (
    method: AxiosRequestConfig['method'],
    path: string,
    data = {},
    params = {},
    responseType: AxiosRequestConfig['responseType'] = 'json',
    logError: boolean,
    version: string
  ) =>
    this.service
      .request({
        url: `${settings.apiUrl}${version}/${path}`,
        method,
        data,
        params,
        responseType,
      })
      .catch((error) => this.handleError(error, logError));

  /**
   * @function
   * @description Interceptor for the error response
   * @param {AxiosError} error is a object with the error response from API.
   * @param {boolean} logError controls whether errorToaster is emitted.
   */
  handleError = (error: AxiosError, logError: boolean) => {
    // emits ErrorMsgToaster if logError is set to true
    if (logError) {
      const values = Object.values(error.response?.data);
      emitErrorMsgToaster({
        content: values,
      });
    }

    const err = {
      ...error,
      response: { ...error.response, data: transformApiErrors(get(() => error.response?.data)) },
    };

    if (err.response) {
      switch (err.response.status) {
        case 401:
          this.unauthorizedHandler();
          break;

        default:
          break;
      }

      return Promise.reject(err);
    }

    return Promise.reject(err);
  };

  /**
   * @function
   * @description Fires the get request
   * @param {string} path is a string of API path.
   * @param {*} params is an object with API params.
   * @param {*} responseType of the request.
   * @param {boolean} logError controls whether errorToaster is emitted.
   */
  get = (path: string, params: any, responseType: any, logError: boolean = false, version: string = 'v1') =>
    this.handleRequest(REQUEST_METHOD.GET, path, {}, params, responseType, logError, version);

  /**
   * @function
   * @description Fires the patch request
   * @param {string} path A relative path that comes after the API URL defined in settings.js, e.g. "reports"
   * @param {Object} data is an object to submit to API.
   * @param {boolean} logError controls whether errorToaster is emitted.
   */
  patch = (path: string, data: { [index: string]: any }, logError: boolean, version: string = 'v1') =>
    this.handleRequest(REQUEST_METHOD.PATCH, path, data, {}, 'json', logError, version);

  /**
   * @function
   * @description Fires the put request
   * @param {string} path A relative path that comes after the API URL defined in settings.js, e.g. "reports"
   * @param {Object} data is an object to submit to API.
   * @param {boolean} logError controls whether errorToaster is emitted.
   * @return {*}
   */
  put = (path: string, data: { [index: string]: any }, logError: boolean, version: string = 'v1') =>
    this.handleRequest(REQUEST_METHOD.PUT, path, data, {}, 'json', logError, version);

  /**
   * @function
   * @description Fires the post request
   * @param {string} path A relative path that comes after the API URL defined in settings.js, e.g. "reports"
   * @param {Object} data is an object to submit to API.
   * @param {*} params is an object with API params.
   * @param {boolean} logError controls whether errorToaster is emitted.
   * @return {*}
   */
  post = (
    path: string,
    data: { [index: string]: any },
    params?: any,
    logError: boolean = false,
    version: string = 'v1'
  ) => this.handleRequest(REQUEST_METHOD.POST, path, data, params, 'json', logError, version);

  /**
   * @function
   * @description Fires the delete request
   * @param {string} path A relative path that comes after the API URL defined in settings.js, e.g. "reports"
   * @param {Object} data is an object to submit to API.
   * @param {boolean} logError controls whether errorToaster is emitted.
   * @return {*}
   */
  delete = (path: string, data: { [index: string]: any }, logError = false, version: string = 'v1') =>
    this.handleRequest(REQUEST_METHOD.DELETE, path, data, {}, 'json', logError, version);
}

const HttpServiceInstance = new HttpService();
export default HttpServiceInstance;
