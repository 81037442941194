const chartMarkerSymbols = [
  'circle',
  'diamond',
  'square',
  'triangle',
  'triangle-down',
  'circle',
  'diamond',
  'square',
  'triangle',
  'triangle-down',
];
export default chartMarkerSymbols;
